import { useStaticQuery, graphql } from "gatsby"

export const useData = () => {
  const data = useStaticQuery(graphql`
    query MyDataQuery {
      allWpPhoto {
        edges {
          node {
            id
            seo {
              title
              metaDesc
              metaKeywords
              breadcrumbs {
                text
                url
              }
              opengraphImage {
                sourceUrl
              }
              canonical
              breadcrumbs {
                text
                url
              }
              opengraphSiteName
              opengraphTitle
              opengraphUrl
            }
            featuredImage {
              node {
                sourceUrl
                title
              }
            }
            order {
              order
            }
            link
            nodeType
            photos {
              photos {
                image {
                  sourceUrl
                  id
                }
              }
            }
            slug
            postTypes {
              date
            }
            title
          }
        }
      }
      allWpEvent {
        edges {
          node {
            id
            title
            link
            slug
            nodeType
            content
            date
            seo {
              title
              metaDesc
              breadcrumbs {
                text
                url
              }
              metaKeywords
              opengraphImage {
                sourceUrl
              }
              canonical
              breadcrumbs {
                text
                url
              }
              opengraphSiteName
              opengraphTitle
              opengraphUrl
            }
            order {
              order
            }
            featuredImage {
              node {
                sourceUrl
              }
            }
            postTypes {
              date
            }
          }
        }
      }
      allWpFilm {
        edges {
          node {
            id
            slug
            link
            nodeType
            order {
              order
            }
            seo {
              title
              metaDesc
              breadcrumbs {
                text
                url
              }
              metaKeywords
              opengraphImage {
                sourceUrl
              }
              canonical
              breadcrumbs {
                text
                url
              }
              opengraphSiteName
              opengraphTitle
              opengraphUrl
            }
            postTypes {
              date
            }
            title
            film {
              film
            }
          }
        }
      }
    }
  `)
  return data
}
