import React, { FC } from "react"
import { IPageTemplateProps } from "./Page"
import { PageTitle } from "components/Shared/PageTitle"
import { BackgroundImage } from "components/Shared/BackgroundImage"
import SEO from "components/Seo"
import Card from "components/Shared/Card"
import { generateContentObject } from "utils"
import { WrapperCards } from "components/Shared/WrapperCards"
import { Wrapper } from "components/Layout"
import { StyledContainer } from "components/Shared/Container"
import { useData } from "hooks/data"
import Breadcrumbs from "components/Breadcumbs"

const ArchiveTemplate: FC<IPageTemplateProps> = ({
  pageContext: { featuredImage, title, template, seo },
}) => {
  const { allWpPhoto, allWpEvent, allWpFilm } = useData()

  const data = (template: string) => {
    if (template === "Foto") return allWpPhoto.edges
    if (template === "Film") return allWpFilm.edges
    if (template === "Events") return allWpEvent.edges
    return []
  }

  return (
    <>
      <SEO
        title={seo ? seo.title : title}
        description={seo ? seo.metaDesc : null}
        image={
          seo && seo.opengraphImage && seo.opengraphImage.sourceUrl
            ? seo.opengraphImage.sourceUrl
            : null
        }
      />
      <BackgroundImage
        style={{
          backgroundImage: `url(${
            featuredImage && featuredImage.node && featuredImage.node.sourceUrl
              ? featuredImage.node.sourceUrl
              : null
          })`,
        }}
      />
      <Wrapper className="content-wrapper">
        <PageTitle style={{ position: "relative" }}>{title}</PageTitle>
        <Breadcrumbs breadcrumbs={seo ? seo.breadcrumbs : null} />
        <StyledContainer>
          <WrapperCards>
            {data(template)
              .sort((a, b) => a.node.order.order - b.node.order.order)
              .map(photo => {
                return (
                  <Card
                    key={photo.node.id}
                    content={generateContentObject(photo.node)}
                  />
                )
              })}
          </WrapperCards>
        </StyledContainer>
      </Wrapper>
    </>
  )
}

export default ArchiveTemplate
